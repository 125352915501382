import $ from 'jquery'
import 'jquery-confirm'
import 'jquery-confirm/css/jquery-confirm.css'
import NotificationToast from "./NotificationToast";
import formControl from "./FormControl";

export function CustomConfirm(text, formElement) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Confimation',
        content: 'Voulez-vous vraiment supprimer <br/><b class="delText">' + text + ' ?</b>',
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: true,
        animation: 'scale',
        type: 'red',
        buttons: {
            Supprimer: {
                btnClass: 'btn__confirm__delete',
                action: function () {
                    document.getElementById(formElement).submit();
                }
            },
            Annuler: function () {
            }
        }
    });

}

export function CustomConfirmExportAllData(url, docName) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Confimation',
        content: 'Voulez-vous vraiment exporter les données ?<br/><b class="delText">Attention cette action sera irréversible.</b>',
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: true,
        animation: 'scale',
        type: 'red',
        buttons: {
            Exporter: {
                btnClass: 'btn__confirm__delete',
                action: function () {
                    let exportAnimation = document.querySelector(".popUp_section.excel_export_popUp")
                    exportAnimation.classList.add("active")

                    fetch(url, {
                        method: "POST"
                    }).then(res => {
                            if (res.status !== 200){
                                return null
                            }
                            return res.blob()
                        }
                    ).then(file => {
                        let tempUrl = URL.createObjectURL(file);
                        const aTag = document.createElement("a");
                        aTag.href = tempUrl;
                        let date = new Date();
                        aTag.download = docName + '.cgp';
                        document.body.appendChild(aTag);
                        aTag.click();
                        URL.revokeObjectURL(tempUrl);
                        aTag.remove();
                        exportAnimation.classList.remove("active");
                        NotificationToast("success", "Exportation des données réussie");
                    }).catch(() => {
                        exportAnimation.classList.remove("active");
                        NotificationToast("error", "Erreur lors de l'exportation...");
                    });
                }
            },
            Annuler: function () {
            }
        }
    });

}

export function CustomConfirmExportAllDataDirect(url) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Confimation',
        content: 'Voulez-vous vraiment exporter les données ?<br/><b class="delText">Attention cette action sera irréversible.</b>',
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: true,
        animation: 'scale',
        type: 'red',
        buttons: {
            Exporter: {
                btnClass: 'btn__confirm__delete',
                action: function () {
                    let exportAnimation = document.querySelector(".popUp_section.excel_export_popUp")
                    exportAnimation.classList.add("active")

                    fetch(url, {
                        method: "POST",
                    })
                        .then(res => res.json())
                        .then(data => {
                            if (typeof data === "object") {
                                let response_request = data
                                exportAnimation.classList.remove("active");
                                NotificationToast(response_request.type, response_request.message);
                            } else {
                                exportAnimation.classList.remove("active");
                                NotificationToast("error", "Erreur lors de l'exportation...");
                            }
                        })
                        .catch(err => {
                            exportAnimation.classList.remove("active");
                            NotificationToast("error", "Erreur XHR: " + err)
                        });
                }
            },
            Annuler: function () {
            }
        }
    });

}

export function controleContratDialog(text = "Confirmation", field) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Vérification',
        content: text,
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: false,
        animation: 'scale',
        type: 'blue',
        buttons: {
            OUI: {
                btnClass: 'btn__confirm__blue',
                action: function () {
                    document.querySelector(".form_step_parent " + field).value = 1
                }
            },
            NON: {
                btnClass: 'btn__confirm__blue__outline',
                action: function () {
                    document.querySelector(".form_step_parent " + field).value = 0
                }
            },
            'JE NE SAIS PAS': {
                btnClass: 'btn__confirm__blue__outline',
                action: function () {
                    document.querySelector(".form_step_parent " + field).value = 2
                }
            }
        }
    });

}

export function controleAddARFDialog(nif, callback, url) {
    $.confirm({
        icon: 'fa fa-question',
        title: 'Vérification ARF',
        content: "Aucune ARF valide n'a été trouvée dans la base.<br><b style='color: #2e89c4'>Voulez-vous ajouter une ARF pour le NIF  " + nif + " ?</b>",
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: false,
        animation: 'scale',
        type: 'blue',
        buttons: {
            OUI: {
                btnClass: 'btn__confirm__blue',
                action: function () {
                    addARFDialog(nif, callback, url)
                }
            },
            NON: {
                btnClass: 'btn__confirm__blue__outline',
                action: function () {
                    document.querySelector(".form_step_parent").setAttribute("data-pif", "true")
                    callback()
                }
            }
        }
    });

}

export function addARFDialog(nif, callback, url) {
    $.confirm({
        title: 'Nouvelle <span style=\'color: #2e89c4\'>ARF NIF N°' + nif + '</span>',
        content: `
        <form action="${url}" class="form-element-cs" id="addArf" method="post" style="margin: 10px 0 0 0;">
            <input type="hidden" name="nif" value="${nif}">
            <div class="field">
                <div class="input-area">
                    <input is="date-picker-today" data-control="noEmpty" autoComplete="off" type="date" placeholder=" " name="date"
                           class="form-control">
                        <label for="date">Date expiration ARF</label>
                        <i class="error error-icon fi-rr-exclamation"></i>
                </div>
                <div class="error error-txt">Veuillez renseigner cet champ</div>
            </div>
        </form>`,
        useBootstrap: false,
        boxWidth: '500px',
        theme: 'modern',
        closeIcon: false,
        animation: 'scale',
        type: 'blue',
        buttons: {
            AJOUTER: {
                btnClass: 'btn__confirm__blue',
                action: function () {
                    let form = document.querySelector("#addArf")
                    let control = formControl(form)

                    if (control === true) {
                        this.buttons.AJOUTER.disable();
                        this.buttons.ANNULER.disable();

                        let url = form.action
                        let payload = new FormData(form)

                        fetch(url, {
                            method: "POST",
                            body: payload,
                        })
                            .then(res => res.json())
                            .then(data => {
                                this.buttons.AJOUTER.enable();
                                this.buttons.ANNULER.enable();
                                if (typeof data === "object") {
                                    let response_request = data.result
                                    if (response_request === true) {
                                        NotificationToast("success", "ARF ajoutée avec succès");
                                        document.querySelector(".form_step_parent").setAttribute("data-pif", "false")
                                    } else {
                                        NotificationToast("error", "Erreur lors de l'opération. Veuillez réessayer de nouveau.");
                                    }
                                } else {
                                    NotificationToast("error", "Erreur lors de l'opération. Veuillez réessayer de nouveau.");
                                }
                                callback()
                            })
                            .catch(err => {
                                NotificationToast("error", "Erreur XHR: " + err)
                                return true;
                            });
                    } else {
                        return false;
                    }
                    return true;
                }
            },
            ANNULER: {
                btnClass: 'btn__confirm__blue__outline',
                action: function () {
                    document.querySelector(".form_step_parent").setAttribute("data-pif", "true")
                    callback()
                }
            }
        }
    });
}
