import AutoNumeric from "autonumeric";

const autoNumericOptionsFcfa = {
    digitGroupSeparator        : ' ',
    decimalCharacter           : ',',
    decimalCharacterAlternative: '.',
    decimalPlaces              : 0,
    currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.suffix,
    roundingMethod             : AutoNumeric.options.roundingMethod.halfUpSymmetric,
    negativePositiveSignPlacement             : 'p',
}

export function numberCurrencyFormaterInput(item){
    item.type = "text"
    new AutoNumeric(item, autoNumericOptionsFcfa);
}

export function numberCurrencyFormater(){

    let formatnumber = document.querySelectorAll(".modal__box form input[data-formatnumber='true']")
   formatnumber.forEach((item) => {
       item.type = "text"
       new AutoNumeric(item, autoNumericOptionsFcfa);
   })
}