import carouselCustomers from "../lib/CarouselCustomers";
import {addElement, getElement, removeFromFavorite} from "../lib/favorites";
import NotificationToast from "../lib/NotificationToast";
import {
    controleAddARFDialog,
    controleContratDialog,
    CustomConfirm,
    CustomConfirmExportAllData,
    CustomConfirmExportAllDataDirect
} from "../lib/CustomConfirm";
import autoComplete from "@tarekraafat/autocomplete.js";
import formControl from "../lib/FormControl";
import {numberCurrencyFormater} from "../lib/numberCurrencyFormat";
import moment from "moment/moment";
import AutoNumeric from "autonumeric";
import {SelectInput, SelectInputTop} from "../component/SelectInput";
import {DatePicker, DatePickerTwo} from "../component/DatePicker";

let Mousetrap = require('mousetrap');
let MousetrapGlobal = require("mousetrap/plugins/global-bind/mousetrap-global-bind.min");

// recuperation de l'hashtag de l'url
window.addEventListener('load', (e) => {

    // Recuperation du current tabs
    let tabs_id = sessionStorage.getItem("tabs__id")

    if (tabs_id !== null && tabs_id !== undefined) {
        let tabs = document.querySelector("[data-tabs__id='" + tabs_id + "']")
        let oldTabs = document.querySelector(".tab-item.active")
        if (oldTabs !== null && oldTabs !== undefined) {
            oldTabs.classList.remove("active")
        }

        if (tabs !== null && tabs !== undefined) {
            tabs.classList.add("active")
            let newActiveTab = document.querySelector('.tabs-content').querySelector('.ctn' + tabs.dataset.id)
            newActiveTab.classList.add('active')

            let topTextPlaceholder = document.querySelector('#topTextPlaceholder')

            if (topTextPlaceholder !== null && topTextPlaceholder !== undefined) {
                topTextPlaceholder.innerHTML = tabs.dataset.content + ' '
            }

            let currentHash = new URL(document.URL).hash

            if (currentHash !== "") {
                let hashFormated = currentHash.split("#")
                let tabOfCurrentHash = newActiveTab.querySelector("[data-id='" + hashFormated[1] + "']")
                if (tabOfCurrentHash !== null && tabOfCurrentHash !== undefined && tabOfCurrentHash.classList.contains("active") === false) {
                    tabOfCurrentHash.click()
                } else {
                    const remove_hash_from_url = () => {
                        let uri = window.location.toString();

                        if (uri.indexOf("#") > 0) {
                            let clean_uri = uri.substring(0,
                                uri.indexOf("#"));

                            window.history.replaceState({},
                                document.title, clean_uri);
                        }
                    }
                    remove_hash_from_url()
                }
            }
        } else {
            let illustration = document.querySelector(".empty-state.calendar")
            if (illustration !== null && illustration !== undefined) {
                illustration.style.display = "flex"
            }
        }
    } else {
        let illustration = document.querySelector(".empty-state.calendar")
        if (illustration !== null && illustration !== undefined) {
            illustration.style.display = "flex"
        }
    }
})

// Custom Shortcut
let search__form__btn = document.querySelector("#search__form__btn")
if (search__form__btn !== null) {
    Mousetrap.bind(['command+k', 'ctrl+k'], function (e) {
        let close = document.querySelector(".close__modal")
        if (close !== null) {
            close.click()
        } else {
            search__form__btn.click()
        }
        return false;
    });
}

let add__form__short = document.querySelector("#add__form__short")
if (add__form__short !== null) {
    Mousetrap.bind(['shift+a'], function (e) {
        let close = document.querySelector(".close__modal")
        if (close !== null) {
            close.click()
        } else {
            add__form__short.click()
        }
        return false;
    });
}

let export__btn_short = document.querySelector("#export__btn_short")
if (export__btn_short !== null) {
    Mousetrap.bind(['shift+x'], function (e) {
        export__btn_short.click()
        return false;
    });
}

let tabs_add__form__short = document.querySelector("#tabs_add__form__short")
if (tabs_add__form__short !== null) {
    Mousetrap.bind(['shift+a'], function (e) {
        let close = document.querySelector(".close__modal")
        let btn = document.querySelector(".tab-content-item.active .button-group-content-item.active button#tabs_add__form__short")
        if (close !== null) {
            close.click()
        } else {
            if (btn !== null) {
                btn.click()
            }
        }
        return false;
    });
}

window.addEventListener("click", (e) => {
    if (!e.target.matches('.icon.fi-rr-menu-dots-vertical') && !e.target.matches('.table_drop_action')) {
        document.querySelectorAll('.table_drop_action').forEach(elm => {
            if (elm.classList.contains("active")) {
                elm.classList.remove("active");
            }
        });
    }

    if (!e.target.matches('.delete__favorite')) {
        let btn = document.querySelector(".delete__favorite")
        if (btn !== null && btn !== undefined) {
            btn.style.display = "none"
        }
    }

    if (document.querySelector(".dropdown .default_option") !== null) {
        if (!e.target.matches('.dropdown .default_option')) {
            if (document.querySelector(".dropdown .default_option + ul").classList.contains("active")) {
                document.querySelector(".dropdown .default_option + ul").classList.remove("active");
            }
        }
    }

});

window.addEventListener("contextmenu", (e) => {
    if (!e.target.matches('.swiper-slide.favoriteBtn')) {
        let btn = document.querySelector(".delete__favorite")
        if (btn !== null && btn !== undefined) {
            btn.style.display = "none"
        }
    }
});

let calcScrollValue = () => {
    let scrollProgress = document.getElementById("progress");
    let progressValue = document.getElementById("progress-value");
    let pos = document.documentElement.scrollTop;
    let calcHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
        scrollProgress.style.display = "grid";
    } else {
        scrollProgress.style.display = "none";
    }
    scrollProgress.addEventListener("click", () => {
        document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#104e94 ${scrollValue}%, #ffffff2e ${scrollValue}%)`;
};
let progresseStatus = document.getElementById("progress");
if (progresseStatus !== null && progresseStatus !== undefined) {
    window.onscroll = calcScrollValue;
    window.onload = calcScrollValue;
}

generateFavorite()

function generateFavorite() {
    let swiper = document.querySelector('.swiper')
    if (swiper !== null && swiper !== undefined) {
        let elements = getElement().reverse()
        if (elements.length === 0) {
            swiper.style.display = "none"
        } else {
            swiper.style.display = "block"
            let html = ""
            elements.forEach(item => {
                html += `
            <div class="swiper-slide favoriteBtn" oncontextmenu="app.removeFavorite(this, event);" onclick="app.favoriteBtn(this);" data-id="${item.id}" data-name="${item.name}" data-revue="${item.revue}" data-audit="${item.audit}">
                <div class="favorites__item">
                    <div class="img_favorite">
                        <img src="/vendors/images/dev_img/folder-cgp.png" alt="logo" width="30">
                    </div>
                    <div class="favorites_title">${item.name}</div>
                </div>
            </div>
            `;
            })
            document.querySelector('.swiper-wrapper').innerHTML = html;
            // Carousel of Customers Favorites
            carouselCustomers()
        }
    }
}

// Modal PopUp showImportPop
export function showImportPop() {
    document.querySelector(".popUp_section.excel_export_popUp").classList.add("active")
    document.querySelector(".popUp_section.excel_export_popUp h1").innerHTML = "Importation en cours..."
}

// Profile Dropdown
export function showProfileDropdown() {
    let profileBox = document.querySelector(".profile-box .profile-box-dropdown")
    if (profileBox !== undefined && profileBox !== null) {
        profileBox.classList.toggle("active")
    }

}

// Modal PopUp generate
export function show_generatePopUp() {
    document.querySelector(".popUp_section").classList.remove("active")
    document.querySelector(".generate-popUp").classList.add("active")
}

// Modal exportModal
export function exportModal() {
    const popUpModal = document.querySelector(".exportModal")
    const clientId = document.querySelector(".exportModal").dataset.id
    const clientName = document.querySelector(".exportModal").dataset.name
    const activeTabs = document.querySelector(".tabs-btn .tab-item.active")

    if (activeTabs !== null) {
        let allMonthLink = document.querySelectorAll('.exportModal .month-PDFlink')
        let allYearLink = document.querySelectorAll('.exportModal .year-PDFlink')

        allMonthLink.forEach((item) => {
            item.href = item.dataset.url + (parseInt(activeTabs.dataset.id, 10) + 1) + "_" + clientId + "_" + parseInt(activeTabs.dataset.year, 10)
            item.dataset.name = item.textContent + " - " + activeTabs.textContent + " " + activeTabs.dataset.year + " (" + clientName + ")"
        })

        allYearLink.forEach((item) => {
            item.href = item.dataset.url + clientId + "_" + parseInt(activeTabs.dataset.year, 10)
            item.dataset.name = item.textContent + " - " + activeTabs.dataset.year + " (" + clientName + ")"
        })

        popUpModal.classList.toggle("active")
    } else {
        NotificationToast('warning', 'Veuillez choisir une opération pour exporter.')
    }
}

export function getExportUrl() {
    let element = document.querySelector(".exportModal #getExportUrl")
    let value = parseInt(element.value, 10)
    let valueText = element.options[element.selectedIndex].text
    if (value !== null && !isNaN(value)) {
        let allMonthLink = element.parentElement.querySelectorAll('.month-PDFlink')
        const clientId = document.querySelector(".exportModal").dataset.id
        const clientName = document.querySelector(".exportModal").dataset.name
        const year = document.querySelector(".exportModal").dataset.year

        allMonthLink.forEach((item) => {
            item.href = item.dataset.url + value + "_" + clientId + "_" + year
            item.dataset.name = item.textContent + " - " + valueText + " " + year + " (" + clientName + ")"
        })
    }
}

export function exportModalOther() {
    const activeTabs = document.querySelector(".tabs-btn .tab-item.active")
    if (activeTabs !== null) {
        let allMonthLink = document.querySelectorAll('.exportModal .month-PDFlink')
        if (document.querySelector(".exportModal").dataset.id === undefined) {
            NotificationToast('warning', 'Veuillez choisir une opération pour exporter.')
            return 0
        }
        const clientId = document.querySelector(".exportModal").dataset.id
        const clientName = document.querySelector(".exportModal").dataset.name
        const year = document.querySelector(".exportModal").dataset.year
        let month_active = document.querySelector(".tab-content-item.active .button-group-item.active")
        if (month_active === null) {
            NotificationToast('warning', 'Veuillez choisir une opération pour exporter.')
            return 0
        }
        let month = {
            "Janvier": 1,
            "Février": 2,
            "Mars": 3,
            "Avril": 4,
            "Mai": 5,
            "Juin": 6,
            "Juillet": 7,
            "Août": 8,
            "Septembre": 9,
            "Octobre": 10,
            "Novembre": 11,
            "Décembre": 12
        }

        allMonthLink.forEach((item) => {
            item.href = item.dataset.url + month[month_active.dataset.content] + "_" + clientId + "_" + year
            item.dataset.name = item.textContent + " - " + month_active.dataset.content + " " + year + " (" + clientName + ")"
        })
    } else {
        NotificationToast('warning', 'Veuillez choisir une opération pour exporter.')
        return 0
    }
    document.querySelector(".exportModal").classList.toggle("active")
}

if (notify !== null && notify !== undefined && notify !== "") {
    if (notify.length > 1) {
        for (const item in notify) {
            NotificationToast(notify[item].type, notify[item].message)
        }
    } else {
        NotificationToast(notify[0].type, notify[0].message)
    }
}

// Gestion des suppressions
let formDelete = document.querySelectorAll(".deleteForm");
formDelete.forEach((item) => {
    item.addEventListener("submit", (e) => {
        e.preventDefault();
        CustomConfirm(item.dataset.content, item.id)
    })
})

// Gestion de l'exportation vers excel
let excel_export = document.querySelectorAll(".excel_export");
excel_export.forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        let exportAnimation = document.querySelector(".popUp_section.excel_export_popUp")
        exportAnimation.classList.add("active")

        let url = item.href;

        if (url === undefined && item.getAttribute('href') !== null) {
            url = item.getAttribute('href')
        }

        let docName = (item.dataset.name).trim()

        fetch(url).then(res => res.blob()).then(file => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            let date = new Date();
            aTag.download = docName + '.xlsx';
            document.body.appendChild(aTag);
            aTag.click();
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
            exportAnimation.classList.remove("active");
            NotificationToast("success", "Exportation Reussi");
        }).catch(() => {
            exportAnimation.classList.remove("active");
            NotificationToast("error", "Erreur d'exportation...");
        });

    })
})

let exportAuditBtn = document.querySelector(".exportAudit")
if (exportAuditBtn !== null && exportAuditBtn !== undefined) {
    exportAuditBtn.addEventListener("click", (e) => {
        e.preventDefault();
        let exportAnimation = document.querySelector(".popUp_section.excel_export_popUp.excel_doc")
        exportAnimation.classList.add("active")

        let url = exportAuditBtn.href;
        let docName = (exportAuditBtn.dataset.name).trim()

        fetch(url).then(res => res.blob()).then(file => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            let date = new Date();
            aTag.download = docName + '.xlsx';
            document.body.appendChild(aTag);
            aTag.click();
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
            exportAnimation.classList.remove("active");
            NotificationToast("success", "Exportation Reussi");
        }).catch(() => {
            exportAnimation.classList.remove("active");
            NotificationToast("error", "Erreur d'exportation...");
        });

    })
}

let export_json = document.querySelector(".export_json");
if (export_json !== null && export_json !== undefined) {
    export_json.addEventListener("click", (e) => {
        e.preventDefault();
        let url = export_json.href;
        let docName = (export_json.dataset.name).trim()
        CustomConfirmExportAllData(url, docName)
    })
}

let export_json_direct = document.querySelector(".export_json_direct");
if (export_json_direct !== null && export_json_direct !== undefined) {
    export_json_direct.addEventListener("click", (e) => {
        e.preventDefault();
        let url = export_json_direct.href;
        CustomConfirmExportAllDataDirect(url)
    })
}

// Gestion des favories
let addFavoriteBtn = document.querySelectorAll('.addFavorites');
addFavoriteBtn.forEach(element => {
    element.addEventListener('click', (e) => {
        e.preventDefault()
        let favorite = {
            "id": element.dataset.id,
            "name": element.dataset.name,
            "revue": element.dataset.revue,
            "audit": element.dataset.audit,
        }
        let result = addElement(favorite)
        if (result === true) {
            NotificationToast("success", favorite.name + " a été ajoutée à vos favories")
            generateFavorite()
        } else {
            NotificationToast("error", favorite.name + " existe déjà dans vos favories")
        }
    })
})

export function favoriteBtn(element) {
    let favorite = {
        "id": element.dataset.id,
        "name": element.dataset.name,
        "revue": element.dataset.revue,
        "audit": element.dataset.audit
    }
    window.location = favorite.audit
}

export function removeFavorite(element, event) {
    event.preventDefault()
    let favorite = {
        "id": element.dataset.id,
        "name": element.dataset.name,
        "revue": element.dataset.revue,
        "audit": element.dataset.audit
    }

    let delete__favorite = document.querySelector(".delete__favorite")
    let left = event.pageX + -5
    let top = event.pageY + -2
    delete__favorite.style.display = "flex"
    delete__favorite.style.top = top + "px";
    delete__favorite.style.left = left + "px";

    delete__favorite.setAttribute("data-id", favorite.id)
    delete__favorite.setAttribute("data-name", favorite.name)
}

let delete__favorite = document.querySelector(".delete__favorite")
if (delete__favorite !== null && delete__favorite !== undefined) {
    delete__favorite.addEventListener('click', (e) => {
        e.preventDefault()
        if (delete__favorite.dataset.id !== "") {
            removeFromFavorite(delete__favorite.dataset.id)
            NotificationToast("success", delete__favorite.dataset.name + " a été supprimé de vos favories")
            generateFavorite()
        }
        delete__favorite.style.display = "none"
        delete__favorite.setAttribute("data-id", "")
        delete__favorite.setAttribute("data-name", "")
    }, false)
}

// DropDown Action menu
//table
let table_drop_action = document.querySelectorAll('.table_drop_action');
table_drop_action.forEach(element => {
    element.addEventListener("click", () => {
        table_drop_action.forEach(elm => {
            if (elm.classList.contains("active") && elm !== element) {
                elm.classList.remove("active");
            }
        });
        element.classList.toggle("active");
    });
});

let all_table = document.querySelectorAll(".table-list table")
all_table.forEach(item => {
    let all_table_dropdown = item.querySelectorAll("tbody tr .table_drop_action .dropdown")
    let last_table_dropdown = all_table_dropdown[all_table_dropdown.length - 1]
    if (last_table_dropdown !== null && last_table_dropdown !== undefined && all_table_dropdown.length > 10) {
        last_table_dropdown.classList.add('last')
    }
});


// Gestion botton tabs de naigation
let tabsBtn = document.querySelectorAll('.tab-item')
if (tabsBtn !== null && tabsBtn !== undefined) {
    tabsBtn.forEach(element => {
        element.addEventListener("click", () => {
            tabsBtn.forEach(elm => {
                if (elm.classList.contains("active") && elm !== element) {
                    elm.classList.remove("active");
                }
            });
            let tabsContent = document.querySelector('.tabs-content')
            let oldTab = tabsContent.querySelector('.tab-content-item.active')
            if (oldTab !== null && oldTab !== undefined) {
                oldTab.classList.remove('active')
            }
            element.classList.add("active");
            tabsContent.querySelector('.ctn' + element.dataset.id).classList.add('active')

            let tabs__id = element.dataset.tabs__id

            sessionStorage.setItem("tabs__id", tabs__id)

            let topTextPlaceholder = document.querySelector('#topTextPlaceholder')

            if (topTextPlaceholder !== null && topTextPlaceholder !== undefined) {
                topTextPlaceholder.innerHTML = element.dataset.content + ' '
            }

            let illustration = document.querySelector(".empty-state.calendar")
            if (illustration !== null && illustration !== undefined) {
                illustration.style.display = "none"
            }
        });
    });
}

// Gestion tabs de naigation
let buttonGroupTabs = document.querySelectorAll('.button-group-item')
if (buttonGroupTabs !== null && buttonGroupTabs !== undefined) {
    buttonGroupTabs.forEach(element => {
        element.addEventListener("click", () => {
            element.parentNode.querySelectorAll('.button-group-item').forEach(elm => {
                if (elm.classList.contains("active") && elm !== element) {
                    elm.classList.remove("active");
                }
            });
            let buttonGroupContent = element.parentNode.parentNode
            let url_ob = new URL(document.URL);
            url_ob.hash = "#" + element.dataset.id;
            let new_url = url_ob.href;
            let oldButtonGroupContent = buttonGroupContent.querySelector(".button-group-content-item.active")
            if (oldButtonGroupContent !== null && oldButtonGroupContent !== undefined) {
                oldButtonGroupContent.classList.remove('active')
            }
            element.classList.add("active");
            buttonGroupContent.querySelector('#' + element.dataset.id).classList.add('active')
            document.location.href = new_url;
        });
    });
}

// Filter Dropp
let filterDrop = document.querySelector(".dropdown .default_option");
if (filterDrop !== null && filterDrop !== undefined) {
    filterDrop.addEventListener("click", () => {
        let filterElm = document.querySelector(".dropdown .default_option + ul");
        filterElm.classList.toggle("active");
    });
}

let showYear = document.querySelector(".show-year .default_option");
let showElmYear = document.querySelector(".show-year .default_option + ul");
if (showYear !== null && showYear !== undefined) {
    showYear.addEventListener("click", () => {
        showElmYear.classList.toggle("active");
    });
}

let showtypeConformite = document.querySelector(".show-typeConformite .default_option");
let showElmtypeConformite = document.querySelector(".show-typeConformite .default_option + ul");
if (showtypeConformite !== null && showYear !== undefined) {
    showtypeConformite.addEventListener("click", () => {
        showElmtypeConformite.classList.toggle("active");
    });
}

// Gestion des recherches
let searchField = document.querySelector(".search_field .input")
if (searchField !== null && searchField !== undefined) {
    const autoCompleteJS = new autoComplete({
        selector: "#autoComplete",
        placeHolder: "Rechercher...",
        data: {
            src: searchList,
            cache: true,
        },
        resultsList: {
            element: (list, data) => {
                if (!data.results.length) {
                    // Create "No Results" message element
                    const message = document.createElement("div");
                    // Add class to the created element
                    message.setAttribute("class", "no_result");
                    // Add message text content
                    message.innerHTML = `<span>Aucun résultat trouvé pour "${data.query}"</span>`;
                    // Append message element to the results list
                    list.prepend(message);
                }
            },
            noResults: true,
            // maxResults: 10,
            tabSelect: true,
        },
        submit: true,
        resultItem: {
            highlight: true
        },
        events: {
            input: {
                selection: (event) => {
                    const selection = event.detail.selection.value;
                    let url = searchField.parentElement.parentElement.parentElement.action
                    const params = new URLSearchParams({
                        q: selection
                    });
                    window.location.href = url + '?' + params.toString();
                }
            }
        }
    });
}

// Gestion revue
function controleFormStep() {

    let formStep = document.querySelector(".modal__box form.form_step_parent")
    if (formStep !== null) {
        let allNextStep = document.querySelectorAll(".modal__box .modal__footer .form_step_next")
        let allPrevStep = document.querySelectorAll(".modal__box .modal__footer .form_step_prev")

        let step__step = document.querySelector(".step__step span")

        allNextStep.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault()

                let currentStep = formStep.querySelector(".form_step_item.active")
                let nextStep = formStep.querySelector(".form_step_item.active + .form_step_item")

                let currentStepFooter = document.querySelector(".modal__box .modal__footer .footer_step_footer.active")
                let nextStepFooter = document.querySelector(".modal__box .modal__footer .footer_step_footer.active + .footer_step_footer")

                let formControlResult = formControl(formStep)
                if (formControlResult === true) {

                    if (nextStep.dataset.id === "2") {
                        if (step__step !== null) step__step.innerHTML = "2/3"
                        getImpotByNature()
                    }

                    if (nextStep.dataset.id === "3") {
                        if (step__step !== null) step__step.innerHTML = "3/3"
                        let getNif = document.querySelector('.form_step_parent #getNif').value
                        let regime = document.querySelector(".form_step_parent #regime").value
                        let type = document.querySelector(".form_step_parent #type").value
                        let tva_dec_value = document.querySelector(".form_step_parent #tva_dec_step")
                        let tva_sup_value = document.querySelector(".form_step_parent #tva_sup_step")

                        if (tva_dec_value !== null) {
                            AutoNumeric.set(tva_dec_value, "")
                        }

                        if (tva_sup_value !== null) {
                            AutoNumeric.set(tva_sup_value, "")
                        }

                        if (type !== "externe" || regime === "A" || regime === "" || regime === 'Régime') {
                            document.querySelector('.form_step_parent #certifie').parentNode.style.display = "none"
                            document.querySelector('.form_step_parent #derogation').parentNode.style.display = "none"
                        } else {
                            document.querySelector('.form_step_parent #certifie').parentNode.style.display = "block"
                            document.querySelector('.form_step_parent #derogation').parentNode.style.display = "block"
                        }

                        if ((getNif === "" || getNif === null) && type === "externe") {
                            document.querySelector('.form_step_parent #cru').parentNode.style.display = "block"
                        } else {
                            document.querySelector('.form_step_parent #cru').parentNode.style.display = "none"
                        }

                        if (type === "acquisition") {
                            if (document.querySelector(".form_step_parent #check-conformite").checked == true) {
                                document.querySelector(".form_step_parent #check-conformite").click()
                            }
                        } else if (type === "paiement") {
                            if (document.querySelector(".form_step_parent #check-conformite").checked == false) {
                                document.querySelector(".form_step_parent #check-conformite").click()
                            }
                        } else {

                            let derogation = document.querySelector(".form_step_parent #derogation")
                            let facture = document.querySelector(".form_step_parent #certifie")
                            let cru = document.querySelector(".form_step_parent #cru")

                            if (derogation.checked === true) {
                                derogation.click()
                            }

                            if (facture.checked === true) {
                                facture.click()
                            }

                            if (cru.checked === true) {
                                cru.click()
                            }
                        }

                        let dispense_facture = document.querySelector('.form_step_parent').dataset.dispensefacture
                        if (dispense_facture === 'true') {
                            let derogation = document.querySelector(".form_step_parent #derogation")
                            if (derogation.checked === false) {
                                derogation.click()
                            }
                        }

                        generateObservations()

                        let tabsActive = document.querySelector(".tabs-btn .tab-item.active")
                        if (tabsActive == null) {
                            CloseModal()
                            NotificationToast('warning', 'Veuillez choisir une opération pour exporter.')
                            return 0
                        }
                        document.querySelector(".form_step_parent #monthForm").value = tabsActive.dataset.id
                        document.querySelector(".form_step_parent #year").value = tabsActive.dataset.year

                        controleTvaDec()
                    }

                    let controleCertifactionFacture = formStep.querySelector(".tile_check_box #certifie")
                    controleCertifactionFacture.addEventListener("change", () => {
                        controleCertfication(controleCertifactionFacture)
                    })

                    let controleDerogationFacture = formStep.querySelector(".tile_check_box #derogation")
                    controleDerogationFacture.addEventListener("change", () => {
                        controleDerogation(controleDerogationFacture)
                    })

                    let controleCruCheck = formStep.querySelector(".tile_check_box #cru")
                    controleCruCheck.addEventListener("change", () => {
                        controleCru(controleCruCheck)
                    })

                    let check_conformite = formStep.querySelector(".tile_check_box #check-conformite")
                    check_conformite.addEventListener("change", () => {
                        controleCheckConformite(check_conformite)
                    })

                    currentStep.classList.remove("active")
                    currentStepFooter.classList.remove("active")

                    nextStep.classList.add("active")
                    nextStepFooter.classList.add("active")
                }
            })
        })

        allPrevStep.forEach((item) => {
            item.addEventListener('click', (e) => {
                e.preventDefault()

                let currentStep = formStep.querySelector(".form_step_item.active")
                let nextStep = formStep.querySelector(".form_step_item.active").previousElementSibling

                if (nextStep.dataset.id === "2") {
                    if (step__step !== null) step__step.innerHTML = "2/3"
                } else if (nextStep.dataset.id === "1") {
                    if (step__step !== null) step__step.innerHTML = "1/3"
                }

                let currentStepFooter = document.querySelector(".modal__box .modal__footer .footer_step_footer.active")
                let nextStepFooter = document.querySelector(".modal__box .modal__footer .footer_step_footer.active").previousElementSibling

                currentStep.classList.remove("active")
                currentStepFooter.classList.remove("active")

                nextStep.classList.add("active")
                nextStepFooter.classList.add("active")
            })
        })

        let typePiece = formStep.querySelector('select#type')
        typePiece.addEventListener('change', () => {
            controleTypePiece(typePiece)
        })

        let nifFounisseur = formStep.querySelector("#getNif")
        if (nifFounisseur !== null && nifFounisseur !== undefined) {
            nifFounisseur.addEventListener('blur', async (e) => {
                if (nifFounisseur.value.length > 0) {
                    if (parseInt(nifFounisseur.value, 10) < 0) {
                        nifFounisseur.value = Math.abs(parseInt(nifFounisseur.value, 10))
                    } else if (parseInt(nifFounisseur.value, 10) === 0) {
                        nifFounisseur.value = 1
                    }
                    nifFounisseur.parentNode.querySelector('.loader-input').style.display = "block";
                    let url_getFournisseurExonere = nifFounisseur.dataset.getfournisseurexonere
                    await fetch(url_getFournisseurExonere + nifFounisseur.value, {
                        method: "POST"
                    })
                        .then((response => response.json()))
                        .then(async (data) => {
                            if (typeof data === "object") {
                                nifFounisseur.parentNode.querySelector('.loader-input').style.display = "none";
                                let response_request = data
                                formStep.querySelector("#getRaison").value = response_request.raison
                                let regime = formStep.querySelector("#regime")
                                regime.querySelectorAll('option').forEach((item) => {
                                    if (item.value === response_request.regime) {
                                        item.setAttribute("selected", "")
                                    }
                                })

                                let dispenseISB = response_request.dispense_isb
                                let dispenseTVA = response_request.dispense_tva
                                let dispenseFacture = response_request.dispense_facture
                                let form = formStep

                                if (parseInt(dispenseISB, 10) === 1) {
                                    form.setAttribute("data-dispenseisb", "true")
                                } else {
                                    form.setAttribute("data-dispenseisb", "false")
                                }

                                if (parseInt(dispenseTVA, 10) === 1) {
                                    form.setAttribute("data-dispensetva", "true")
                                } else {
                                    form.setAttribute("data-dispensetva", "false")
                                }

                                if (parseInt(dispenseFacture, 10) === 1) {
                                    form.setAttribute("data-dispensefacture", "true")
                                } else {
                                    form.setAttribute("data-dispensefacture", "false")
                                }

                                let url_getCollecteurTVA = nifFounisseur.dataset.getcollecteurtva
                                await fetch(url_getCollecteurTVA + nifFounisseur.value, {
                                    method: "POST"
                                })
                                    .then((response => response.json()))
                                    .then((data) => {
                                        if (typeof data === "object") {
                                            let response_request = data
                                            let collecteurTVA = formStep.querySelector("#collecteurTVA")
                                            collecteurTVA.querySelectorAll('option').forEach((item) => {
                                                if (item.value === "yes") {
                                                    item.setAttribute("selected", "")
                                                }
                                            })
                                        } else {
                                            formStep.querySelector("#collecteurTVA").parentElement.querySelector(".ss-deselect.ss-hide").click()
                                        }
                                    })

                            } else {
                                nifFounisseur.parentNode.querySelector('.loader-input').style.display = "none";
                                let form = formStep
                                form.setAttribute("data-dispenseisb", "false")
                                form.setAttribute("data-dispensetva", "false")
                                form.setAttribute("data-dispensefacture", "false")

                                let url_getAppproFournisseur = nifFounisseur.dataset.getappprofournisseur
                                await fetch(url_getAppproFournisseur + nifFounisseur.value, {
                                    method: "POST"
                                })
                                    .then((response => response.json()))
                                    .then(async (data) => {
                                        if (typeof data === "object") {
                                            let response_request = data
                                            formStep.querySelector("#getRaison").value = response_request.fournisseur
                                            let regime = formStep.querySelector("#regime")
                                            regime.querySelectorAll('option').forEach((item) => {
                                                if (item.value === response_request.regime) {
                                                    item.setAttribute("selected", "")
                                                }
                                            })
                                            let url_getCollecteurTVA = nifFounisseur.dataset.getcollecteurtva
                                            await fetch(url_getCollecteurTVA + nifFounisseur.value, {
                                                method: "POST"
                                            })
                                                .then((response => response.json()))
                                                .then((data) => {
                                                    if (typeof data === "object") {
                                                        let response_request = data
                                                        let collecteurTVA = formStep.querySelector("#collecteurTVA")
                                                        collecteurTVA.querySelectorAll('option').forEach((item) => {
                                                            if (item.value === "yes") {
                                                                item.setAttribute("selected", "")
                                                            }
                                                        })
                                                    } else {
                                                        formStep.querySelector("#collecteurTVA").parentElement.querySelector(".ss-deselect.ss-hide").click()
                                                    }
                                                })
                                        } else {
                                            let url_getCollecteurTVA = nifFounisseur.dataset.getcollecteurtva
                                            await fetch(url_getCollecteurTVA + nifFounisseur.value, {
                                                method: "POST"
                                            })
                                                .then((response => response.json()))
                                                .then((data) => {
                                                    if (typeof data === "object") {
                                                        let response_request = data
                                                        formStep.querySelector("#getRaison").value = response_request.raison
                                                        let regime = formStep.querySelector("#regime")
                                                        regime.querySelectorAll('option').forEach((item) => {
                                                            if (item.value === response_request.regime) {
                                                                item.setAttribute("selected", "")
                                                            }
                                                        })
                                                        let collecteurTVA = formStep.querySelector("#collecteurTVA")
                                                        collecteurTVA.querySelectorAll('option').forEach((item) => {
                                                            if (item.value === "yes") {
                                                                item.setAttribute("selected", "")
                                                            }
                                                        })
                                                    } else {
                                                        formStep.querySelector("#collecteurTVA").parentElement.querySelector(".ss-deselect.ss-hide").click()
                                                    }
                                                })
                                        }
                                    })
                            }
                        })
                } else {
                    let form = document.querySelector('.form_step_parent')
                    form.setAttribute("data-dispenseisb", "false")
                    form.setAttribute("data-dispensetva", "false")
                    form.setAttribute("data-dispensefacture", "false")
                }

                let tabsActive = document.querySelector(".tabs-btn .tab-item.active")
                if (tabsActive == null) {
                    CloseModal()
                    return 0
                }

                if (parseInt(tabsActive.dataset.year, 10) > 2022) {
                    await controleARF()
                }
            })
        }

        let natureOperation = formStep.querySelector("#getnature")
        natureOperation.addEventListener('change', () => {
            controleContrat()
        })

        let montantTTC = formStep.querySelector("#montantttc")
        montantTTC.addEventListener('focusout', () => {
            getSomme(montantTTC)
        })

        let tva_sup_step = formStep.querySelector("#tva_sup_step")
        if (tva_sup_step !== null) {
            let tva_dec = formStep.querySelector('#tva_dec_step')
            let prorata = formStep.querySelector('input.prorata')
            let taux = parseInt(prorata.dataset.taux)

            tva_sup_step.addEventListener('keyup', () => {
                getTvaDec()
            })

            tva_sup_step.addEventListener('change', () => {
                getTvaDec()
            })

            prorata.addEventListener('click', (e) => {
                getTvaDec()
            })

            function getTvaDec() {
                let val = parseInt(tva_sup_step.value.replaceAll(' ', ''), 10)
                if (!isNaN(taux) && prorata.checked) {
                    val = val * (taux / 100)
                }
                AutoNumeric.set(tva_dec, val)
            }
        }
    }
}

function controleCertfication(element) {
    if (element.checked === true) {
        document.querySelector(".form_step_parent #derogation").parentNode.style.display = "none"
        let getNif = document.querySelector('.form_step_parent #getNif').value
        let type = document.querySelector(".form_step_parent #type").value
        if (getNif === "" || getNif === null) {
            if (document.querySelector('.form_step_parent #cru').checked === true) {
                if (type !== "acquisition") {
                    if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                        document.querySelector(".form_step_parent #check-conformite").click()
                    }
                }
            }
        } else {
            if (type !== "acquisition") {
                if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                    document.querySelector(".form_step_parent #check-conformite").click()
                }
            }
        }
    } else {
        let derogation = document.querySelector(".form_step_parent #derogation")
        derogation.parentNode.style.display = "block"
        if (derogation.checked !== true) {
            if (document.querySelector(".form_step_parent #check-conformite").checked === true) {
                document.querySelector(".form_step_parent #check-conformite").click()
            }
        }
    }
    controleTvaDec()
    generateObservations()
}

function controleDerogation(element) {
    let regime = document.querySelector(".form_step_parent #regime").value
    let certfie = document.querySelector(".form_step_parent .form_step_item  .checkbox #certifie").parentElement
    if (element.checked === true) {
        certfie.style.display = "none"
        if (regime !== "A" && regime !== "" && regime !== 'Régime') {
            let getNif = document.querySelector('.form_step_parent #getNif').value
            let type = document.querySelector(".form_step_parent #type").value
            if (getNif === "" || getNif === null) {
                if (document.querySelector('.form_step_parent #cru').checked === true) {
                    if (type !== "acquisition") {
                        if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                            document.querySelector(".form_step_parent #check-conformite").click()
                        }
                    }
                }
            } else {
                if (type !== "acquisition") {
                    if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                        document.querySelector(".form_step_parent #check-conformite").click()
                    }
                }
            }
        }
    } else {
        certfie.style.display = "block"
        if (document.querySelector(".form_step_parent #check-conformite").checked === true) {
            document.querySelector(".form_step_parent #check-conformite").click()
        }
    }
    controleTvaDec()
    generateObservations()
}

function controleCru(element) {
    let regime = document.querySelector(".form_step_parent #regime").value
    if (element.checked === true) {
        let getNif = document.querySelector('.form_step_parent #getNif').value
        if (getNif === "" || getNif === null) {
            let regime = document.querySelector(".form_step_parent #regime").value
            let type = document.querySelector(".form_step_parent #type").value

            if (regime !== "A" && regime !== "" && regime !== 'Régime') {
                let certifie = document.querySelector('.form_step_parent #certifie')
                let derogation = document.querySelector('.form_step_parent #derogation')

                if (certifie.checked === true || derogation.checked === true) {
                    if (type !== "acquisition") {
                        if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                            document.querySelector(".form_step_parent #check-conformite").click()
                        }
                    }
                }
            } else {
                if (type !== "acquisition") {
                    if (document.querySelector(".form_step_parent #check-conformite").checked === false) {
                        document.querySelector(".form_step_parent #check-conformite").click()
                    }
                }
            }
        }
        document.querySelector(".form_step_parent #produit_cruForm").value = 1;
    } else {
        if (document.querySelector(".form_step_parent #check-conformite").checked === true) {
            document.querySelector(".form_step_parent #check-conformite").click()
        }
        document.querySelector(".form_step_parent #produit_cruForm").value = 0;
    }
    generateObservations()
}

function controleContrat() {
    let field = document.querySelector(".form_step_parent #getnature")
    if (field.options[field.selectedIndex] !== undefined) {
        let value = field.options[field.selectedIndex].text

        if (value.indexOf('Location') !== -1) {
            controleContratDialog("Le contrat de bail de l'opération <b>" + value + "</b> est-il enregistré ?", "#contrat_bail")
        } else {
            document.querySelector(".form_step_parent #contrat_bail").value = ""
        }

        if (value.indexOf('Prestataires') !== -1) {
            controleContratDialog("Le contrat de prestation de service de l'opération <b>" + value + "</b> est-il enregistré ?", "#contrat_prestation")
        } else {
            document.querySelector(".form_step_parent #contrat_prestation").value = ""
        }
    }
}

async function controleARF() {
    // controle ARF
    let nif = parseInt(document.querySelector(".form_step_parent #getNif").value, 10)
    let typePiece = document.querySelector(".form_step_parent #type").value;
    let date = document.querySelector(".form_step_parent #date").value;
    if (typePiece === "externe" && date !== '') {
        if (isNaN(nif)) {
            document.querySelector(".form_step_parent").setAttribute("data-pif", "true")
        } else {
            let url_getARFClient = document.querySelector(".form_step_parent #getNif").dataset.getarfclient
            let url_addARFClient = document.querySelector(".form_step_parent #getNif").dataset.addarfclient
            await fetch(url_getARFClient + nif + '/' + moment(date).unix(), {
                method: "POST"
            })
                .then((response => response.json()))
                .then((data) => {
                    if (typeof data === "object") {
                        let response_request = data.result
                        if (response_request === false) {
                            controleAddARFDialog(nif, getImpotByNature, url_addARFClient)
                        } else {
                            document.querySelector(".form_step_parent").setAttribute("data-pif", "false")
                        }
                    }
                })
        }
    }
}

function getImpotByNature() {
    let value = parseInt(document.querySelector(".form_step_parent #getnature").value, 10)
    let montant = document.querySelector(".form_step_parent #montantttc").value
    if (!isNaN(value) && montant !== null) {
        let url_getNature = document.querySelector(".form_step_parent #getnature").dataset.getnature
        fetch(url_getNature + value, {
            method: "POST"
        })
            .then((response => response.json()))
            .then((data) => {
                if (typeof data === "object") {
                    let response_request = data

                    let isb = document.querySelector(".form_step_parent #isb")
                    let pif = document.querySelector(".form_step_parent #pif")
                    let isbnr = document.querySelector(".form_step_parent #isbnr")
                    let ircdc = document.querySelector(".form_step_parent #ircdc")
                    let irvm = document.querySelector(".form_step_parent #irvm")
                    let irbp = document.querySelector(".form_step_parent #irbp")
                    let irbh = document.querySelector(".form_step_parent #irbh")
                    let tva_ct = document.querySelector(".form_step_parent #tva_ct")
                    let tva_rs = document.querySelector(".form_step_parent #tva_rs")

                    let montant = document.querySelector(".form_step_parent #montantttc").value

                    let dispense_isb = document.querySelector('.form_step_parent').dataset.dispenseisb
                    let dispense_tva = document.querySelector('.form_step_parent').dataset.dispensetva
                    let collecteur_tva = document.querySelector('.form_step_parent').dataset.collecteur
                    let as_pif = document.querySelector('.form_step_parent').dataset.pif
                    let collecteur_tva_fournissuer = document.querySelector(".form_step_parent #collecteurTVA").value

                    // ISB
                    if (parseInt(response_request.ISB, 10) === 0) {
                        isb.value = "ISB - NEANT"
                        isb.classList.remove("colored-input")
                        isb.setAttribute('disabled', '')
                    } else {
                        if (dispense_isb === 'true') {
                            isb.value = "ISB - DISPENSE"
                            isb.classList.remove("colored-input")
                            isb.setAttribute('disabled', '')
                        } else {
                            let getNif = document.querySelector(".form_step_parent #getNif").value
                            if (getNif === null || getNif === undefined || getNif === "") {
                                isb.value = Math.round(parseInt(montant, 10) * 0.05) + " CFA (ISB - 5%)"
                            } else {
                                isb.value = Math.round(parseInt(montant, 10) * 0.02) + " CFA (ISB - 2%)"
                            }
                            isb.classList.add("colored-input")
                        }
                    }

                    // PIF
                    if (parseInt(response_request.PIF, 10) === 0 || as_pif === "false" || document.querySelector(".form_step_parent #type").value !== "externe") {
                        pif.value = "PIF - NEANT"
                        pif.classList.remove("colored-input")
                    } else {
                        pif.value = Math.round(parseInt(montant, 10) * 0.10) + " CFA (PIF - 10%)"
                        pif.classList.add("colored-input")
                    }

                    // ISBNR
                    if (parseInt(response_request.ISBNR, 10) === 0) {
                        isbnr.value = "ISBNR - NEANT"
                        isbnr.classList.remove("colored-input")
                    } else {
                        isbnr.value = Math.round(parseInt(montant, 10) * 0.16) + " CFA (ISBNR - 16%)"
                        isbnr.classList.add("colored-input")
                    }

                    // ISBNR
                    if (parseInt(response_request.IRCDC, 10) === 0) {
                        ircdc.value = "IRCDC - NEANT"
                        ircdc.classList.remove("colored-input")
                    } else {
                        ircdc.value = Math.round(parseInt(montant, 10) * 0.20) + " CFA (IRCDC - 20%)"
                        ircdc.classList.add("colored-input")
                    }

                    // IRVM
                    if (parseInt(response_request.IRVM, 10) === 0) {
                        irvm.value = "IRVM - NEANT"
                        irvm.classList.remove("colored-input")
                    } else {
                        irvm.value = Math.round(parseInt(montant, 10) * 0.10) + " CFA (IRVM - 10%)"
                        irvm.classList.add("colored-input")
                    }

                    // IRBP
                    if (parseInt(response_request.IRBP, 10) === 0) {
                        irbp.value = "IRBP - NEANT"
                        irbp.classList.remove("colored-input")
                    } else {
                        irbp.value = Math.round(parseInt(montant, 10) * 0.12) + " CFA (IRBP - 12%)"
                        irbp.classList.add("colored-input")
                    }

                    // IRBH
                    if (parseInt(response_request.IRBH, 10) === 0) {
                        irbh.value = "IRBH - NEANT"
                        irbh.classList.remove("colored-input")
                    } else {
                        irbh.value = Math.round(parseInt(montant, 10) * 0.10) + " CFA (IRBH - 10%)"
                        irbh.classList.add("colored-input")
                    }

                    // TVA CT
                    if (parseInt(response_request.TVA_CT, 10) === 0) {
                        tva_ct.value = "TVA CT - NEANT"
                        tva_ct.classList.remove("colored-input")
                    } else {
                        tva_ct.value = Math.round(((parseInt(montant, 10)) * 0.19)) + " CFA (TVA CT - 19%)"
                        tva_ct.classList.add("colored-input")
                    }

                    // TVA RS
                    if (parseInt(response_request.TVA_RS, 10) === 0) {
                        tva_rs.value = "TVA RS - NEANT"
                        tva_rs.classList.remove("colored-input")
                    } else {
                        if (dispense_tva === 'true') {
                            isb.value = "TVA RS - DISPENSE"
                            tva_rs.classList.remove("colored-input")
                        } else {
                            if (collecteur_tva === "yes" && (collecteur_tva_fournissuer === "no" || collecteur_tva_fournissuer === "Collecteur TVA" || collecteur_tva_fournissuer === "")) {
                                tva_rs.value = Math.round(((parseInt(montant, 10) / 1.19) * 0.19)) + " CFA (TVA RS - 19%)"
                                tva_rs.classList.add("colored-input")
                            } else {
                                tva_rs.value = "TVA RS - NEANT"
                                tva_rs.classList.remove("colored-input")
                            }
                        }
                    }


                    // Diables
                    let allInputDisabled = document.querySelectorAll("input[data-disabledevent='true']:disabled")
                    allInputDisabled.forEach((item) => {
                        let parent = item.parentElement
                        parent.addEventListener('dblclick', (e) => {
                            item.disabled = !item.disabled
                        })
                    })
                }
            })
    }
}


function controleTypePiece(element) {
    let fseurField = document.querySelector(".form_step_parent #appro_fournisseur")
    if (element.value !== "externe" && element.value !== "") {
        fseurField.style.display = "none"
        document.querySelector(".form_step_parent #getNif").value = "";
        document.querySelector(".form_step_parent #getRaison").value = "";
        (document.querySelector(".form_step_parent #regime").parentNode).querySelector(".ss-deselect.ss-hide").click();
        (document.querySelector(".form_step_parent #collecteurTVA").parentNode).querySelector(".ss-deselect.ss-hide").click();
    } else {
        fseurField.style.display = "flex"
        document.querySelector(".form_step_parent #getNif").value = "";
        document.querySelector(".form_step_parent #getRaison").value = "";
        (document.querySelector(".form_step_parent #regime").parentNode).querySelector(".ss-deselect.ss-hide").click();
        (document.querySelector(".form_step_parent #collecteurTVA").parentNode).querySelector(".ss-deselect.ss-hide").click();
    }
}

function generateObservations() {
    let observations = document.querySelector(".form_step_parent #observations")
    let dispense_isb = document.querySelector('.form_step_parent').dataset.dispenseisb
    let dispense_tva = document.querySelector('.form_step_parent').dataset.dispensetva

    let collecteurTVA = document.querySelector('.form_step_parent #collecteurTVA').value

    observations.value = ""

    let numPiece = document.querySelector(".form_step_parent #piece")

    let nif = document.querySelector(".form_step_parent #getNif")

    let regime = document.querySelector(".form_step_parent #regime").value

    if (numPiece.value.length === 0) {
        observations.value += "ABSENCE NUMERO DE PIECE\n"
    }

    let type = document.querySelector(".form_step_parent #type").value
    if (type === "acquisition") {
        observations.value += "PIECE INTERNE D'ACQUISITION\n"
    }

    if (nif.value.length === 0 && type === "externe") {
        observations.value += "FOURNISSEUR NON IMMATRICULE\n"
    }

    if (regime !== "A" && regime !== "" && regime !== 'Régime') {
        let certifie = document.querySelector(".form_step_parent #certifie")
        let derogation = document.querySelector(".form_step_parent #derogation")

        if (certifie.checked === false && derogation.checked === false) {
            observations.value += "FACTURE NON CERTIFIEE\n"
        }
    }

    if (dispense_isb === 'true') {
        observations.value += "DISPENSE PRÉCOMPTE ISB\n"
    }

    if (dispense_tva === 'true') {
        observations.value += "DISPENSE TVA RETENUE À LA SOURCE\n"
    }

    if (collecteurTVA === 'yes') {
        observations.value += "COLLECTEUR TVA\n"
    }
}

export function ControleTauxCa(element, form) {
    if (element.checked) {
        document.querySelector(form + " #taux-ca").style.display = "inherit"
        document.querySelector(form + " #tva_19").checked = true
    } else {
        document.querySelector(form + " #taux-ca").style.display = "none"
        document.querySelector(form + " #tva_19").checked = false
        document.querySelector(form + " #tva_10").checked = false
        document.querySelector(form + " #tva_5").checked = false
    }
}

export function ControleTaux19(element, form) {
    if (element.checked) {
        document.querySelector(form + " #tva_5").checked = false;
        document.querySelector(form + " #tva_10").checked = false;
    } else {
        document.querySelector(form + " #tva_10").checked = true;
    }
}

export function ControleTaux5(element, form) {
    if (element.checked) {
        document.querySelector(form + " #tva_19").checked = false;
        document.querySelector(form + " #tva_10").checked = false;
    } else {
        document.querySelector(form + " #tva_10").checked = true;
    }

}

export function ControleTaux10(element, form) {
    if (element.checked) {
        document.querySelector(form + " #tva_19").checked = false;
        document.querySelector(form + " #tva_5").checked = false;
    } else {
        document.querySelector(form + " #tva_5").checked = true;
    }

}

export function ControleTauxISB(element, form) {
    if (element.checked) {
        document.querySelector(form + " #taux-isb").style.display = "inherit"
        document.querySelector(form + " #isb_2").checked = true
    } else {
        document.querySelector(form + " #taux-isb").style.display = "none"
        document.querySelector(form + " #isb_2").checked = false
        document.querySelector(form + " #isb_5").checked = false
    }
}

export function ControleISB5(element, form) {
    document.querySelector(form + " #isb_2").checked = !element.checked;
}

export function ControleISB2(element, form) {
    document.querySelector(form + " #isb_5").checked = !element.checked;
}


export function controleCheckConformite(element) {
    if (element.checked === true) {
        element.nextElementSibling.querySelector('p').textContent = "Conforme"
        element.nextElementSibling.querySelector('p').dataset.text = "Conforme"
        document.querySelector(".form_step_parent #conformeForm").value = "yes"
    } else {
        element.nextElementSibling.querySelector('p').textContent = "Non Conforme"
        element.nextElementSibling.querySelector('p').dataset.text = "Non Conforme"
        document.querySelector(".form_step_parent #conformeForm").value = "no"
    }
}

export function removeAttribute(element, attribute) {
    element.removeAttribute(attribute)
}

function getSomme(element) {
    if (element.value.length > 0) {
        try {
            element.value = Math.round(eval(element.value))
            if (element.value > 3000000) {
                let mode_paiment = document.querySelector('.form_step_parent #mode_paiement')
                mode_paiment.setAttribute("data-control", "noEmpty")
            } else {
                let mode_paiment = document.querySelector('.form_step_parent #mode_paiement')
                mode_paiment.parentElement.parentElement.classList.remove("error")
                mode_paiment.removeAttribute("data-control")
            }
        } catch (e) {
            element.value = null
        }

    }
}

export function controleTvaDec() {
    let getRegime = document.querySelector('.form_step_parent #regime').value
    let getNature = document.querySelector('.form_step_parent #getnature').value
    let certfie = document.querySelector(".form_step_parent .form_step_item  .checkbox #certifie").checked
    let derogation = document.querySelector(".form_step_parent .form_step_item  .checkbox #derogation").checked
    let currentClientRegime = document.querySelector(".form_step_parent").dataset.regime

    if ((getRegime === "R" || getRegime === "S") && (currentClientRegime === "R" || currentClientRegime === "S") && (getNature === "1" || getNature === "5") && (certfie || derogation)) {
        document.querySelector(".form_step_parent #tva_dec_sup_step").style.display = "flex"
        document.querySelector(".form_step_parent #tva_dec_step").value = ""
        if (document.querySelector(".form_step_parent #tva_sup_step") !== null) document.querySelector(".form_step_parent #tva_sup_step").value = ""
    } else {
        document.querySelector(".form_step_parent #tva_dec_sup_step").style.display = "none"
        document.querySelector(".form_step_parent #tva_dec_step").value = ""
        if (document.querySelector(".form_step_parent #tva_sup_step") !== null) document.querySelector(".form_step_parent #tva_sup_step").value = ""
    }
}

let redirect__btn = document.querySelectorAll(".redirect__btn")
redirect__btn.forEach((item) => {
    item.addEventListener('click', (e) => {
        e.preventDefault();
        let url = item.dataset.url;
        window.location = url
    })
})

export function showMoreEditRevueConfig() {
    let allTile = document.querySelectorAll("#generateEditForm .tile_check_box")
    allTile.forEach((item) => {
        item.classList.toggle("no-visible")
    })
}

export function checkAllMonth() {
    let allMonth = document.querySelectorAll(".month__item")
    allMonth.forEach((item) => {
        item.click()
    })
}

let allSubmitBtn = document.querySelectorAll("input[type=\"submit\"]")
allSubmitBtn.forEach((item) => {
    item.addEventListener("click", (e) => {
        setTimeout(() => {
            item.setAttribute("disabled", "")
        }, 100)
        setTimeout(() => {
            item.removeAttribute("disabled")
        }, 1000)
    })
})

let stepForm = document.querySelector(".form_step_parent")
if (stepForm !== null) {
    let activeStep = stepForm.querySelector(".form_step_item.active").dataset.id
    stepForm.addEventListener("submit", (e) => {
        activeStep = stepForm.querySelector(".form_step_item.active").dataset.id
        if (activeStep != "3") {
            e.preventDefault()
            stepForm.querySelector(".footer_step_footer.active .form_step_next").click()
        }
    })
}

let JS_Call_Url_Get_Form = document.querySelectorAll(".JS_Call_Url_Get_Form")
JS_Call_Url_Get_Form.forEach((item) => {
    item.addEventListener('click', () => {
        let url = item.dataset.url
        if (url !== null && url !== undefined) {
            fetch(url, {
                method: "POST",
                body: null,
            })
                .then(res => res)
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else {
                        NotificationToast("error", "Erreur lors de l'opération.")
                    }
                })
                .then(data => {
                    let generateEditForm = document.querySelector("#JS_GenerateForm")
                    if (data === undefined || generateEditForm === null) {
                        return 0
                    }
                    generateEditForm.innerHTML = data.message

                    if (generateEditForm.querySelector(".modal__footer input[type='submit']") !== null) {
                        Mousetrap.bindGlobal(['shift+s'], function (e, combo) {
                            let btn = generateEditForm.querySelector(".modal__footer .footer_step_footer.active input[type='submit']")
                            if (btn === null) {
                                btn = generateEditForm.querySelector(".modal__footer input[type='submit']")
                            }
                            if (btn !== null) {
                                btn.click()
                            }
                            return false
                        });
                    }
                    let form = generateEditForm.querySelector("form")

                    if (form != null) {
                        form.setAttribute("method", "post")
                    }

                    let requiredText = generateEditForm.querySelector("[data-control ~= 'noEmpty']")
                    if (requiredText !== null) {
                        if (generateEditForm.querySelector(".required__placeholder") == null) {

                            let paragraph = document.createElement('p')
                            paragraph.classList.add('required__placeholder')
                            paragraph.innerHTML = `<i class="fi-rr-exclamation"></i> champs obligatoires`

                            generateEditForm.querySelector(".modal__container").insertBefore(paragraph, generateEditForm.querySelector(".modal__footer"))
                        }
                    }

                    // Toggle Switch Edit Modal
                    toggleEditForm()

                    // Controle form step
                    controleFormStep()

                    // assujettie_partiel_config
                    let assujettie_partiel_config = generateEditForm.querySelector('#assujettie_partiel_config')
                    if (assujettie_partiel_config !== null) {
                        assujettie_partiel_config.addEventListener('click', () => {
                            let taux_prorata = generateEditForm.querySelector('#taux_prorata')
                            if (taux_prorata !== null) {
                                if (assujettie_partiel_config.checked) {
                                    taux_prorata.disabled = false
                                } else {
                                    taux_prorata.disabled = true
                                }
                            }
                        })
                    }


                    setTimeout(function () {
                        generateEditForm.querySelector('.modal__box').classList.add('active')
                        document.querySelector("body").style.overflow = "hidden"
                    }, 50)

                    let getfocus = document.querySelector("input[data-getfocus='true'] + input")
                    if (getfocus !== null) {
                        getfocus.focus()
                    }

                    // Close Modal
                    let close__modal = generateEditForm.querySelectorAll(".close__modal")
                    if (close__modal !== null && close__modal !== undefined) {
                        close__modal.forEach((item) => {
                            item.addEventListener("click", () => {
                                CloseModal()
                            })
                        })
                    }

                    // Submit Form
                    let modalForm__submit = generateEditForm.querySelector(".modalForm__submit")
                    if (modalForm__submit !== null && modalForm__submit !== undefined) {
                        modalForm__submit.addEventListener("click", () => {
                            submitForm(modalForm__submit)
                        })

                        let form = generateEditForm.querySelector("form")
                        form.addEventListener("submit", (e) => {
                            e.preventDefault()
                            submitForm(modalForm__submit)
                        })
                    }

                    let addField__btn = generateEditForm.querySelector("button.addField__btn")
                    if (addField__btn !== null) {
                        addField__btn.addEventListener('click', (e) => {
                            e.preventDefault()
                            let model = generateEditForm.querySelector(".model_field")
                            let target = generateEditForm.querySelector(".target_field")
                            target.innerHTML += model.innerHTML

                            numberCurrencyFormater()

                            let deleteField__btn = generateEditForm.querySelectorAll("form .deleteField__btn")
                            deleteField__btn.forEach((item) => {
                                item.addEventListener('click', (e) => {
                                    e.preventDefault()
                                    item.parentElement.remove()
                                })
                            })
                        })

                        let deleteField__btn = generateEditForm.querySelectorAll("form .deleteField__btn")
                        deleteField__btn.forEach((item) => {
                            item.addEventListener('click', (e) => {
                                e.preventDefault()
                                item.parentElement.remove()
                            })
                        })
                    }

                    numberCurrencyFormater()

                    let dynaic__search = generateEditForm.querySelector("form#dynaic__search")
                    if (dynaic__search !== null) {
                        let input = dynaic__search.querySelector("input[name='q']")
                        let checkbox = dynaic__search.querySelectorAll("input[type='checkbox']")
                        let urlSearch = dynaic__search.action

                        checkbox.forEach((item) => {
                            item.addEventListener('change', (e) => {
                                e.preventDefault()
                                sendData()
                            })
                        })
                        input.addEventListener('keyup', (e) => {
                            e.preventDefault()
                            sendData()
                        })

                        dynaic__search.addEventListener('submit', (e) => {
                            e.preventDefault()
                            sendData()
                        })

                        function sendData() {
                            if (input.value.length >= 0) {
                                generateEditForm.querySelector("#search__query").textContent = input.value
                                let payload = new FormData(dynaic__search)
                                fetch(urlSearch, {
                                    method: "POST",
                                    body: payload,
                                })
                                    .then(res => res.json())
                                    .then(data => {
                                        generateEditForm.querySelector("#search__count").textContent = "(" + data.total + " opérations)"
                                        generateEditForm.querySelector(".table-list").innerHTML = data.message

                                        let search__editBtn = generateEditForm.querySelectorAll(".JS_Call_Url_Get_Form")
                                        search__editBtn.forEach((item) => {
                                            item.addEventListener('click', () => {
                                                let url = item.dataset.url
                                                if (url !== null && url !== undefined) {
                                                    fetch(url, {
                                                        method: "POST",
                                                        body: null,
                                                    })
                                                        .then(res => res)
                                                        .then(response => {
                                                            if (response.status === 200) {
                                                                return response.json()
                                                            } else {
                                                                NotificationToast("error", "Erreur lors de l'opération.")
                                                            }
                                                        })
                                                        .then(data => {
                                                            let generateEditForm = document.querySelector("#JS_GenerateForm")
                                                            if (data === undefined || generateEditForm === null) {
                                                                return 0
                                                            }

                                                            generateEditForm.innerHTML = data.message

                                                            let form = generateEditForm.querySelector("form")

                                                            if (form != null) {
                                                                form.setAttribute("method", "post")
                                                            }

                                                            setTimeout(function () {
                                                                generateEditForm.querySelector('.modal__box').classList.add('active')
                                                                document.querySelector("body").style.overflow = "hidden"
                                                            }, 50)

                                                            let requiredText = generateEditForm.querySelector("[data-control ~= 'noEmpty']")
                                                            if (requiredText !== null) {
                                                                if (generateEditForm.querySelector(".required__placeholder") == null) {

                                                                    let paragraph = document.createElement('p')
                                                                    paragraph.classList.add('required__placeholder')
                                                                    paragraph.innerHTML = `<i class="fi-rr-exclamation"></i> champs obligatoires`

                                                                    generateEditForm.querySelector(".modal__container").insertBefore(paragraph, generateEditForm.querySelector(".modal__footer"))
                                                                }
                                                            }

                                                            // Toggle Switch Edit Modal
                                                            toggleEditForm()

                                                            // Close Modal
                                                            let close__edit__modal = generateEditForm.querySelectorAll(".close__modal")
                                                            if (close__edit__modal !== null && close__modal !== undefined) {
                                                                close__edit__modal.forEach((item) => {
                                                                    item.addEventListener("click", () => {
                                                                        CloseModal()
                                                                    })
                                                                })
                                                            }

                                                            // Submit Form
                                                            let modalForm__edit__submit = generateEditForm.querySelector(".modalForm__submit")
                                                            if (modalForm__edit__submit !== null && modalForm__submit !== undefined) {
                                                                modalForm__edit__submit.addEventListener("click", () => {
                                                                    submitForm(modalForm__edit__submit)
                                                                })

                                                                let form = generateEditForm.querySelector("form")
                                                                form.addEventListener("submit", (e) => {
                                                                    e.preventDefault()
                                                                    submitForm(modalForm__edit__submit)
                                                                })
                                                            }
                                                        })
                                                }
                                            })
                                        })

                                        let formDelete = generateEditForm.querySelectorAll(".deleteForm");
                                        formDelete.forEach((item) => {
                                            item.addEventListener("submit", (e) => {
                                                e.preventDefault();
                                                CustomConfirm(item.dataset.content, item.id)
                                            })
                                        })
                                    })
                                    .catch(err => {
                                        NotificationToast("error", "Erreur XHR: " + err)
                                    });
                            }
                        }
                    }
                })
                .catch(err => {
                    NotificationToast("error", "Erreur XHR: " + err)
                });

            return true
        }
    })
})

function CloseModal() {
    const popUpModal = document.querySelector(".modal__box")
    popUpModal.classList.toggle("active")
    document.querySelector("body").style.overflow = "auto"
    setTimeout(function () {
        document.querySelector("#JS_GenerateForm").innerHTML = ""
    }, 200)
    Mousetrap.unbind('shift+s');
}

function submitForm(element) {
    element.setAttribute("disabled", "")
    let animation = document.querySelector(".modal__box .modal__container")
    let formElement = document.querySelector(".modal__body form")
    let payload = new FormData(formElement)
    let formControlResult = formControl(formElement, payload)

    if (formControlResult === true) {
        animation.classList.add("show__loader")
        if (formElement.classList.contains("form_step_parent")) {
            formElement.querySelectorAll("input:disabled").forEach((item) => {
                item.removeAttribute("disabled")
            })
        }
        formElement.submit()
    }
    setTimeout(() => {
        element.removeAttribute("disabled")
    }, 1000)
}

let submit__form__access = document.querySelector(".submit__form__access")
if (submit__form__access !== null) {
    submit__form__access.addEventListener('click', (e) => {
        document.querySelector("form#form__access").submit()
    })
}

function toggleEditForm() {
    let switch_btn = document.querySelector(".modal__box .modal__header #JS_editModal")
    if (switch_btn !== null) {
        verifyToggle(switch_btn)
        switch_btn.addEventListener('click', () => {
            verifyToggle(switch_btn)
        })
    }

    function verifyToggle(switch_btn) {
        if (switch_btn.checked === false) {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let requiredIcon1 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + input + label + i")
            requiredIcon1.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let requiredIcon3 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label + i")
            requiredIcon3.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "none"
            }
            document.querySelector(".required__placeholder").style.display = "none"
        } else {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                if (item.getAttribute("forceDisabled") == null) {
                    item.removeAttribute('disabled')
                }
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                if (item.getAttribute("forceDisabled") == null) {
                    item.removeAttribute('disabled')
                }
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.removeAttribute('disabled')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })

            let requiredIcon1 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + input + label + i")
            requiredIcon1.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })

            let requiredIcon3 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label + i")
            requiredIcon3.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })
            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "block"
            }
            document.querySelector(".required__placeholder").style.display = "flex"
        }
    }
}
