export function saveElement(element){
    localStorage.setItem("favorite", JSON.stringify(element));
}

export function getElement(){
    let favorites = localStorage.getItem("favorite");
    if(favorites == null){
        return [];
    }else{
        return JSON.parse(favorites)
    }
}

export function addElement(element){
    let favorites = getElement();
    let foundElement = favorites.find(p => p.id === element.id);
    if(foundElement !== undefined){
        return false
    }else{
        favorites.push(element);
    }
    saveElement(favorites)
    return true
}

export function removeFromFavorite(id){
    let favorites = getElement();
    favorites = favorites.filter(p => p.id !== id)
    saveElement(favorites);
}