import flatpickr from "flatpickr";
const French = require("flatpickr/dist/l10n/fr.js").default.fr;
import 'flatpickr/dist/flatpickr.min.css'
flatpickr.localize(French);

export class DatePicker extends HTMLInputElement{

    connectedCallback(){
        this.date = flatpickr(this, {
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            allowInput: true
        });
    }

    disconnectedCallback(){
        this.date.destroy()
    }
}

export class DatePickerTwo extends HTMLInputElement{

    connectedCallback(){
        this.date = flatpickr(this, {
            altInput: true,
            mode: "range",
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            allowInput: true
        });
    }

    disconnectedCallback(){
        this.date.destroy()
    }
}


export class DatePickerTodayMin extends HTMLInputElement{

    connectedCallback(){
        this.date = flatpickr(this, {
            altInput: true,
            altFormat: "d/m/Y",
            dateFormat: "Y-m-d",
            allowInput: true,
        });
        this.date.calendarContainer.classList.add("last__zindex")
    }

    disconnectedCallback(){
        this.date.destroy()
    }
}

customElements.define("date-picker", DatePicker, {extends: "input"})
customElements.define("date-picker-two", DatePickerTwo, {extends: "input"})
customElements.define("date-picker-today", DatePickerTodayMin, {extends: "input"})